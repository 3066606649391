import React, { useState, useEffect } from "react";
import { Button, Modal, Upload, Tag, Alert } from "antd";
import { detectFace, getFace } from "../../services/face";
import { norm } from "mathjs";
import {
  setIn,
  setOut,
  updateIn,
  getLogs,
} from "../../services/timeattendance";
import Init from "../../components/InitPatrol";
import { uploadImageRawService } from "../../services/upload";
import moment from "moment";

export default function InputApp() {
  const [visible, setVisible] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [match, setMatch] = useState([]);
  const [image, setImage] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [Log, setLog] = useState([]);
  const [croppedImages, setCroppedImages] = useState({});
  const [inVisible, setInVisible] = useState(false);
  const [outVisible, setOutVisible] = useState(false);

  const handleOk = () => {
    setVisible(false);
  };

  const handleIn = () => {
    setInVisible(true);
    const updatedMatch = [...match];
    match.forEach((m, index) => {
      setIn({
        site_uuid: JSON.parse(localStorage.getItem("device"))[1],
        employee_name: m.name,
        employee_idcard: m.idcard,
        employee_birthday: m.birthday,
        employee_license: m.license,
        employee_phone: m.phone,
        lat: JSON.parse(localStorage.getItem("location"))?.lat,
        long: JSON.parse(localStorage.getItem("location"))?.lon,
        similarity: m.sim,
        in: new Date(),
      }).then((res) => {
        if (res) {
          uploadImageRawService(
            croppedImages[m.idcard],
            "siamai",
            `timeAttendance/${JSON.parse(localStorage.getItem("device"))[1]}/${
              m.idcard
            }-${res[0].id}.jpg`
          );
          updateIn({
            site_uuid: JSON.parse(localStorage.getItem("device"))[1],
            employee_idcard: m.idcard,
            employee_image: `timeAttendance/${
              JSON.parse(localStorage.getItem("device"))[1]
            }/${m.idcard}-${res[0].id}.jpg`,
          });

          updatedMatch[index] = { ...m, status: "ลงเวลาเข้าสำเร็จ" };
        } else {
          updatedMatch[index] = { ...m, status: "พบการลงเวลาเข้าแล้ว" };
          // setMatch(...match.filter((emp) => emp.idcard !== m.idcard));
        }
        setMatch([]);
        setTimeout(() => {
          setMatch(updatedMatch);
        }, 1000);
      });
    });
    getLogs(JSON.parse(localStorage.getItem("device"))[1]).then((res) => {
      setLog(res);
    });
    setInVisible(false);
  };

  const handleOut = () => {
    setOutVisible(true);
    const updatedMatch = [...match];
    match.forEach((m, index) => {
      setOut({
        site_uuid: JSON.parse(localStorage.getItem("device"))[1],
        employee_idcard: m.idcard,
        out: new Date(),
      }).then((res) => {
        if (res) {
          console.log(res);
          updatedMatch[index] = { ...m, status: "ลงเวลาออกสำเร็จ" };
        } else {
          updatedMatch[index] = { ...m, status: "พบการลงเวลาออกแล้ว" };
          // setMatch(...match.filter((emp) => emp.idcard !== m.idcard));
        }
        setMatch([]);
        setTimeout(() => {
          setMatch(updatedMatch);
          console.log(updatedMatch);
        }, 1000);
      });
    });
    getLogs(JSON.parse(localStorage.getItem("device"))[1]).then((res) => {
      setLog(res);
    });
    setOutVisible(false);
  };

  function computeSim(feat1, feat2) {
    feat1 = feat1.flat();
    feat2 = feat2.flat();

    const dotProduct = feat1.reduce(
      (acc, val, index) => acc + val * feat2[index],
      0
    );
    const normFeat1 = norm(feat1);
    const normFeat2 = norm(feat2);

    const sim = dotProduct / (normFeat1 * normFeat2);

    return sim;
  }

  const handleCancel = () => {
    setVisible(false);
  };

  const cropImage = (image, bbox) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        const [x1, y1, x2, y2] = bbox;

        canvas.width = x2 - x1;
        canvas.height = y2 - y1;
        ctx.drawImage(img, x1, y1, x2, y2, 0, 0, x2, y2);
        canvas.toBlob((blob) => {
          resolve(URL.createObjectURL(blob));
        }, "image/png");
      };

      img.onerror = reject;
      img.src = URL.createObjectURL(image);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("device")) {
      getFace(JSON.parse(localStorage.getItem("device"))[1]).then((res) => {
        setEmployee(res);
      });
      getLogs(JSON.parse(localStorage.getItem("device"))[1]).then((res) => {
        setLog(res);
      });
    }
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setFileImage(file);
    setImage(file);
    detectFace(file)
      .then((res) => {
        if (res["data"]["data"].length === 0) {
          alert("ไม่พบใบหน้าในภาพ");
          return;
        }

        const matchedEmployees = [];

        for (const ref of res["data"]["data"]) {
          const simResults = employee
            .map((emp) => {
              const sim1 = computeSim(emp.vector1, ref["embedding"]);
              const sim2 = computeSim(emp.vector2, ref["embedding"]);
              const sim3 = computeSim(emp.vector3, ref["embedding"]);
              console.log(emp.name, sim1, sim2, sim3);

              if (sim1 > 0.6 || sim2 > 0.6 || sim3 > 0.6) {
                return {
                  ...emp,
                  sim1,
                  sim2,
                  sim3,
                  sim: Math.max(sim1, sim2, sim3),
                  bbox: ref["bbox"],
                };
              }
              return null;
            })
            .filter((emp) => emp !== null);

          matchedEmployees.push(...simResults);
        }

        if (matchedEmployees.length > 0) {
          setMatch(matchedEmployees);
          setVisible(false);
          matchedEmployees.forEach(async (m) => {
            const croppedImage = await cropImage(file, m.bbox);
            setCroppedImages((prev) => ({ ...prev, [m.idcard]: croppedImage }));
          });
          onSuccess("ok");
        }
      })
      .catch((error) => {
        console.error("Error detecting face:", error);
      });
  };

  return (
    <div className="text-center">
      <Init />
      <Modal
        title="ยืนยันการเข้างาน"
        okText="ยืนยัน"
        open={inVisible}
        onOk={handleIn}
        onCancel={() => setInVisible(false)}
      ></Modal>
      <Modal
        title="ยืนยันการออกงาน"
        okText="ยืนยัน"
        open={outVisible}
        onOk={handleOut}
        onCancel={() => setOutVisible(false)}
      ></Modal>
      <Modal
        title="ถ่ายภาพใบหน้า :"
        open={visible}
        onOk={handleOk}
        okText="ตรวจสอบ"
        onCancel={handleCancel}
      >
        <Upload
          customRequest={dummyRequest}
          listType="picture-card"
          maxCount={1}
          multiple
          accept="image/x-png,image/jpeg"
          capture="environment"
        >
          {"+ ถ่ายภาพ"}
        </Upload>
      </Modal>
      <h1>การบันทึกเข้า - ออกงาน</h1>
      <Button
        className="w-[320px] h-[55px] bg-[#00204A] text-[24px] mt-4 text-[#FFFFFF]"
        onClick={() => setVisible(true)}
      >
        ถ่ายภาพใบหน้า
      </Button>
      {match.map((m) => (
        <div key={m.idcard} className="mt-4 grid grid-cols-2">
          <div className="flex flex-col space-y-2 items-center justify-center">
            {croppedImages[m.idcard] && (
              <img
                src={croppedImages[m.idcard]}
                alt="face"
                className="w-[100px] h-[100px] rounded-[20px]"
              />
            )}
            {/* <img
              src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${m.image1}`}
              alt="face"
              className="w-[100px] h-[100px] rounded-[20px]"
            /> */}
          </div>
          <div className="text-left ml-[-30px]">
            <h3 className="p-0 m-0">ชื่อ : {m.name}</h3>
            <h4 className="p-0 m-0">เลขบัตร : {m.idcard}</h4>
            <h4 className="p-0 m-0 mb-2">ใบอนุญาตเลขที่ : {m.license}</h4>
            {(m.status === "ลงเวลาเข้าสำเร็จ" ||
              m.status === "ลงเวลาออกสำเร็จ") && (
              <Tag color="green">{m.status}</Tag>
            )}
            {(m.status === "พบการลงเวลาเข้าแล้ว" ||
              m.status === "พบการลงเวลาออกแล้ว") && (
              <Tag color="red">{m.status}</Tag>
            )}
          </div>
        </div>
      ))}
      {match.length > 0 && (
        <>
          <Button
            className="w-[320px] h-[55px] bg-[#31A627] text-[24px] mt-4 text-[#FFFFFF]"
            onClick={() => {
              setInVisible(true);
            }}
          >
            บันทึกเข้า
          </Button>

          <Button
            className="w-[320px] h-[55px] bg-[#D10000] text-[24px] mt-4 text-[#FFFFFF]"
            onClick={() => {
              setOutVisible(true);
            }}
          >
            บันทึกออก
          </Button>

          <Button
            className="w-[320px] h-[55px] bg-[#000000] text-[24px] mt-4 text-[#FFFFFF]"
            onClick={() => {
              setMatch([]);
              setImage(null);
              setFileImage(null);
            }}
          >
            ล้างข้อมูล
          </Button>
        </>
      )}
      <div className="mt-4">
        <h3>ประวัติการลงเวลา</h3>
        <div className="grid grid-cols-1 gap-4">
          {Log.map((data) => (
            <Alert
              message={
                <>
                  {data.employee_name} <br></br> เข้า:{" "}
                  {moment(new Date(data.in)).format("DD/MM/YYYY HH:mm")}{" "}
                  <br></br> ออก:{" "}
                  {data.out
                    ? moment(new Date(data.out)).format("DD/MM/YYYY HH:mm")
                    : "ยังไม่ออก"}
                </>
              }
              type={data.out ? "success" : "error"}
              showIcon
            />
          ))}
        </div>
      </div>
    </div>
  );
}
