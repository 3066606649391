// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBK5qitMkaX6SjfZreDzh5c3gnACTFdPO0",
  authDomain: "siam-ai-core.firebaseapp.com",
  projectId: "siam-ai-core",
  storageBucket: "siam-ai-core.firebasestorage.app",
  messagingSenderId: "308630813621",
  appId: "1:308630813621:web:3cf50f70490b1a504b77fc",
  measurementId: "G-JRVW5F311S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

export const requestNotificationPermission = async () => {
  try {
    // ตรวจสอบว่าเบราว์เซอร์รองรับ Notification API หรือไม่
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications');
      return null;
    }

    let permission;
    
    if (isIOS()) {
      console.log('Running on iOS');
      // สำหรับ iOS ใช้ callback approach
      permission = await new Promise((resolve) => {
        Notification.requestPermission((result) => {
          resolve(result);
        });
      });
    } else if (isAndroid()) {
      console.log('Running on Android');
      // สำหรับ Android ใช้ Promise approach
      permission = await Notification.requestPermission();
    } else {
      console.log('Running on other platform');
      // สำหรับ platform อื่นๆ ตรวจสอบว่ารองรับ Promise หรือไม่
      if (Notification.requestPermission.toString().indexOf('Promise') !== -1) {
        permission = await Notification.requestPermission();
      } else {
        permission = await new Promise((resolve) => {
          Notification.requestPermission((result) => {
            resolve(result);
          });
        });
      }
    }

    if (permission === 'granted') {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'BM5fkoV2SLO9_fhHr3kxYS4MUZUnGYpEgTzYspEec84siuGbmmQg7b05WLc2NP5RohvcjK3ueLhJpk_RsjH7isY'
        });
        console.log('Notification permission granted. Token:', token);
        return token;
      } catch (tokenError) {
        console.error('Error getting token:', tokenError);
        return null;
      }
    } else {
      console.warn('Notification permission not granted');
      return null;
    }
  } catch (error) {
    console.error('Error in requestNotificationPermission:', error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
